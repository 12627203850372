
import {on, find, findAllIn, addClass, removeClass, hasClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import Modal from 'bootstrap/js/dist/modal';
export function init () {
    onFind('.js-initial-modal-open',function (element) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if(urlParams.has('openModal')){
            let modal = new Modal(element);
            modal.show();
        }
    });
}