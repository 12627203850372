"use strict";
import * as smoothScroll from "@elements/smooth-scroll";
import initModulesInScope, {onFind} from "@elements/init-modules-in-scope";
import {find, findAllIn, findIn} from "@elements/dom-utils";
import {getPrefixedDataSet} from '@elements/data-set-utils';

const defaultSelectors = {
    list: '.js-anchor-nav__list',
    item: '.js-anchor-nav__item'
};

const defaultOptions = {
    renderItem: function (text, anchor, img) {
        return '<li class="nav-item anchor-nav__item list-inline-item"><a class="nav-link" href="#' + anchor + '"><span>' + text + '</span> + <img src="' + img + '" alt="" className=""> + </a></li>';
    },
    smoothScroll: false,
    scrollSpy: false,
    scrollSpyTarget: '',
    scrollSpyOffset: 10,
    scrollSpyMethod: 'auto'
};

let elementsOptions;

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.list, function (baseElement) {
        createAnchorNav(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createAnchorNav(baseElement, options = defaultOptions, selectors = defaultSelectors) {

    elementsOptions = {
        ...defaultOptions,
        ...options,
        ...getPrefixedDataSet('anchor-nav', baseElement)
    };

    let list = baseElement,
        listItem = findAllIn(selectors.item, document.getElementsByTagName('body')[0]);

    listItem.forEach((listItem) => {
        let title = listItem.dataset.anchorNavTitle,
            titleForAnchor = title.replace(/[^a-z]/gi, '').toLowerCase(),
            img = listItem.dataset.img;

        if(listItem.getAttribute('id')){
            addNavItem(elementsOptions.renderItem, list,title,listItem.getAttribute('id'), img);
        }else{
            listItem.setAttribute('id', 'anchor-' + titleForAnchor);
            addNavItem(elementsOptions.renderItem, list,title, 'anchor-' + titleForAnchor, img);
        }
    });

    if(elementsOptions.scrollSpy){
        elementsOptions.initScrollSpy(elementsOptions);
    }

    if(elementsOptions.smoothScroll){
        smoothScroll.init();
    }
}

function addNavItem(renderItem, list, text, anchor, img) {
    console.log(img);

    list.insertAdjacentHTML('beforeend', renderItem(text, anchor, img));
    initModulesInScope(list.lastChild);

    if(findIn(`[href='#${anchor}']`,list).length < 1) {
        list.append(renderItem(text, anchor))
    }
}

