import './scripts/common';
import './scripts/common-nav';
import {onFind} from "@elements/init-modules-in-scope";

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

import * as smoothScroll from '@elements/smooth-scroll';
smoothScroll.init({scrollOffset: 30, initialPosition: true});


import * as ajaxModal from './scripts/ajax-modal';
ajaxModal.init();

import * as anchorNav from './scripts/anchor-nav';
anchorNav.init({
        renderItem: function (text, anchor, img) {
            return '' +
                '<li class="nav-item anchor-nav__item list-inline-item">' +
                    '<a class="js-smoothscroll__link" data-scroll-offset="140" href="#' + anchor + '">' +
                        '<span class="anchor-nav__title">' + text + '</span>' +
                        '<img src="' + img + '" alt="">' +
                    '</a>' +
                '</li>';
        },

        smoothScroll: true,
        scrollSpy: false,
        scrollSpyTarget: '.js-anchor-nav',
        scrollSpyOffset: 160
    },
    {
        list: '.js-anchor-nav__list',
        item: '.js-anchor-nav__item'
    }
);


import * as ajaxFormMap from './scripts/ajax-form-map';
ajaxFormMap.init({
    allowScriptTags: true
});

import * as affix from '@elements/affix';
affix.init({}, {
    base:'.js-affix',
    placeholder:'.js-affix__placeholder',
    container: '.js-affix__container'
});

import * as slideOnScroll from './scripts/slide-on-scroll';
slideOnScroll.initInScope();

import * as googleMap from "./scripts/google-map";
googleMap.init({
    mapOptions: {
        zoom: 15,
        maxZoom: 18,
    },
    clustering: true,
    poiStyles: () => createPoiStyles(),
    onActivateMarker: (marker) => console.log("marker", marker),
    onDeactivateMarker: (marker) => console.log("marker", marker)

});

import {createApp} from 'vue';
import tooltipDirective from "./scripts/checkout/directives/tooltip/";

onFind('.vue-initialize-module', function(element) {

    let componentModule = element.dataset.initializeModule || false;
    if(componentModule) {
        let vueApp = createApp({});
        const getModule = () => import(`./scripts/checkout/templates/${componentModule}.vue`);
        getModule()
            .then(function(moduleName) {
                vueApp.component(componentModule, moduleName.default);
                console.log('vueApp', vueApp);
                tooltipDirective(vueApp);
                vueApp.mount(element);
            }).catch(function(evt) {
            console.error(evt);
        })
    }
});

import * as actionChanger from "@elements/action-changer";
actionChanger.init();

import * as openCartSidebar from "./scripts/open-cart-sidebar";
openCartSidebar.init();

import * as popperJS from "./scripts/libs/popper";
popperJS.init();

import * as initPopovers from "./scripts/init-popovers";
initPopovers.init();

import Modal from 'bootstrap/js/dist/modal';
onFind('[data-bs-toggle="modal"]',function (element) {
    new Modal(element);
});

import * as initModalOpen from "./scripts/initialModalOpen";
initModalOpen.init();

import * as countCartItems from './scripts/count-cart-items';
countCartItems.init();
