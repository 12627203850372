"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import * as googleMap from "@elements/google-map";
import {initAjaxFormMap as initAjaxFormMapGoogle} from "./ajax-form-google";
import {findAll, findIn, on, findAllIn, find} from '@elements/dom-utils';
import {onEnterViewPort, isElementInViewport, isElementVisible} from "@elements/viewport-utils";

import {
    createPoiStyles,
    getMapStyling,
    onActivateMarker,
    onDeactivateMarker,
    getGoogleInfoBoxOptions,
    createClusterStyles
} from "./google-map";


export function init () {

    findAll('.js-ajax-form-map').map(googleMapElement => {
        let lat = +googleMapElement.getAttribute('data-google-map-initial-lat') || 47.076668;
        let lng = +googleMapElement.getAttribute('data-google-map-initial-lng') || 15.421371;

        let selectors = {
            result: '.js-ajax-form-map__result',
            loading: '.js-ajax-form-map__loading',
            notifications: '.js-ajax-form-map__notifications',
            form: '.js-ajax-form-map__form',
            additionalForm: '.js-ajax-form-map__additional-form',
            errorArea: '.js-ajax-form-map__error-area',
            retry: '.js-ajax-form-map__retry',
            link: '.js-ajax-form-map__link',
            reset: '.js-ajax-form-map__reset',
        };


        let map = initAjaxFormMapGoogle(googleMapElement, {
            element: findIn('.js-ajax-form-map__map__canvas', googleMapElement),
            poiStyles: () => createPoiStyles(),
            infoBoxOptions: () => getGoogleInfoBoxOptions(),
            clustering: true,
            clusteringOptions: createClusterStyles(),
            onActivateMarker: (marker, api) => onActivateMarker(marker, api),
            onDeactivateMarker: (marker, api) => onDeactivateMarker( marker, api),
            mapOptions: {
                center: {lat: lat, lng: lng},
                zoom: 10,
                scrollwheel: true,
                mapTypeControl: false,
                streetViewControl: false,
                styles: getMapStyling()
            }
        }, selectors);

        console.log('map', map);


        onEnterViewPort(find('.js-ajax-form-map__map__canvas'), function (element) {
            googleMap.getApi(element).then((api) => {
                api.centerMap();
            });
        }, {
            offset: 0
        });
    });

}

